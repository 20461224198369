import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
const ContactPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        background: (theme) => theme.palette.background.gradient,
        p: 2,
      }}
    >
      <Container>
        <div>
          <br />
          <Button
            onClick={handleBack}
            size="large"
            variant="back"
            startIcon={<ArrowBackIos />}
          >
            Back
          </Button>
        </div>
        <br />
        <Card sx={{ p: 2 }}>
          <CardContent>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Contact Information
            </Typography>
            <Typography variant="h6">
              Please contact me via Email or LinkedIn:
            </Typography>
            <br />

            <Typography variant="h6">
              <a
                href="mailto:taylordearden@gmail.com"
                style={{ color: "#022B3A" }}
              >
                taylordearden@gmail.com
              </a>
            </Typography>
            <br />

            <Typography variant="h6">
              LinkedIn:
              <Button
                variant="outlined"
                href="https://www.linkedin.com/in/taylor-kyle-dearden"
                target="_blank"
                sx={{ ml: 1, color: "#022B3A" }} // Match your primary color
              >
                Visit LinkedIn Profile
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ContactPage;
