import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Grid2,
  Button,
  Container,
  CardHeader,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
const skills1 = {
  "Front-End Fundamentals": [
    "HTML5",
    "CSS3",
    "JavaScript (ES6+)",
    "Responsive design",
    "Object Oriented Programming",
  ],
  "Front-End Frameworks": ["React.js", "Angular", "Vue.js", "Next.js"],
  "UI/State Resources": [
    "jQuery",
    "Bootstrap",
    "Tailwind CSS",
    "Material UI",
    "Carbon Design System",
    "Redux, Zustand",
  ],

  "API Integration": [
    "RESTful APIs",
    "GraphQL",
    "Axios",
    "Fetch API",
    "OAuth integration",
  ],
  "Testing and Quality Assurance": [
    "Unit testing",
    "Integration testing",
    "End-to-end testing",
    "Debugging and troubleshooting",
  ],
  "Accessibility": [
    "Web accessibility compliance",
    "ARIA roles",
    "Keyboard navigation",
    "Screen reader optimization",
    "Contrast testing tools",
  ],
  "Performance Optimization": [
    "Lazy loading",
    "Code splitting",
    "Asset optimization",
    "Caching strategies",
  ],


  "Build Tools and Workflow": [
    "Webpack",
    "Vite",
    "Babel",
    "ESLint",
    "Prettier",
    "npm, yarn, pnpm",
  ],
  "Design Tools": ["Figma", "UX collaboration", "Wireframing"],

  "Back-End Development": ["Node.js", "Express", ".NET", "RESTful API Design"],
  "Cloud & Deployment": [
    "Cloud services deployment (Azure, AWS)",
    "Version control (Git, GitHub, GitLab)",
  ],
  "Database Management": [
    "SQL Databases (MySQL, PostgreSQL)",
    "Database architecture and optimization",
  ],

  "Collaboration & Methodologies": [
    "Agile methodologies",
    "Jira for task and project management",
    "Cross-functional team collaboration",
    "Code reviews and mentoring",
    "Pair programming",
  ],
  "Project Management": [
    "Product planning and roadmap creation",
    "Feature flag management and release strategies",
  ],
  "Testing & Documentation": [
    "Test-driven development (TDD)",
    "Integration and unit testing",
    "Writing and maintaining technical documentation",
  ],
};

const categoryColors = {
  "Front-End Fundamentals": "#FF1675",
  "Front-End Frameworks": "#FF1675",
  "UI/State Resources": "#FF1675", 
  "Testing and Quality Assurance": "#329EF9", 
  "Accessibility": "#329EF9", 
  "Performance Optimization": "#329EF9", 
  "API Integration": "#FF1675", 
  "Build Tools and Workflow": "rgba(75, 51, 209, 0.80)",
  "Design Tools": "rgba(75, 51, 209, 0.80)", 
  "Back-End Development": "#FC7335",
  "Database Management": "#2A2753", 
  "Cloud & Deployment": "#FC7335",
  "Collaboration & Methodologies": "#431B9D",
  "Project Management": "#431B9D",
  "Testing & Documentation": "#431B9D", 
};

const SkillsPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  const SkillsListChips = () => {
    return (
      <Grid2 container spacing={3}>
        {Object.keys(skills1).map((category) => (
          <Grid2 item size={{ sx: 12, sm: 6, md: 4 }} key={category}>
            <Typography variant="body2" component="strong">
              {category}
            </Typography>
            <br />
            <br />
            <Grid2
              container
              spacing={2}
              sx={{ mb: 4 }}
              direction="row"
              flexWrap="wrap"
            >
              {skills1[category].map((skill, index) => (
                <Grid2 item key={index}>
                  <Chip
                    label={skill}
                    style={{
                      backgroundColor: categoryColors[category],
                      color: "#fff",
                    }}
                  />
                </Grid2>
              ))}
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
    );
  };

  return (
    <Box sx={{ background: (theme) => theme.palette.background.soft }}>
      <Box>
        <Box p={3}>
          <Box
            sx={{
              background: (theme) => theme.palette.background.soft,
              pb: 8,
            }}
          >
            <Container>
              <Button
                size="large"
                onClick={handleBack}
                variant="back"
                startIcon={<ArrowBackIos />}
              >
                Back
              </Button>
              <br />
              <br />
              <Card variant="glass">
                <CardContent>
                  <Typography variant="h4">Skills Overview</Typography>
                  <br />
                  <Typography variant="body1">
                    Explore a general list of skills I've developed in my
                    professional experience as a Software Engineer:
                  </Typography>
                </CardContent>
              </Card>
            </Container>
            <br />
            <br />
            <Container>
              <Card sx={{ p: 4 }}>
                <SkillsListChips />
              </Card>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SkillsPage;
