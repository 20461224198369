import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Chip,
  ListItemAvatar,
  Button,
  useMediaQuery,
  Container,
  CardHeader,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import ImageCarousel from "../ImageCarousel";
const ProjectDetail = ({
  link,
  company,
  title,
  description,
  problem,
  solution,
  outcome,
  technologiesUsed,
  images,
  features,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box>
      <Container>
        <Card
          variant="glass"
          sx={{
            mb: 4,
            position: "relative",
          }}
        >
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom>
              {title}
            </Typography>

            {company && (
              <Typography component="p" gutterBottom color="white">
                {company}
              </Typography>
            )}
            <Typography variant="body1" component="p">
              {description}
            </Typography>
          </CardContent>

          {link && (
            <Box
              sx={{
                position: isMobile ? "static" : "absolute", // Change position based on screen size
                top: isMobile ? "auto" : "1rem", // Reset top position on mobile
                right: isMobile ? "auto" : "1rem", // Reset right position on mobile
                m: isMobile ? 2 : 0,
                mt: isMobile ? 2 : 0, // Add margin-top on mobile for spacing
              }}
            >
              <Button
                variant="contained"
                color="primary"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Button>
            </Box>
          )}
        </Card>

        <Grid container spacing={4}>
          <Grid item size={{ xs: 12, md: 6 }} sx={{ mb: 4 }}>
            <Card variant="" sx={{ height: "100%", p: 2 }}>
              <CardContent>
                <Typography variant="h5">Technologies Used</Typography>
                <br />
                <br />
                {technologiesUsed.map((tech, index) => (
                  <Chip
                    background=""
                    key={tech}
                    label={tech}
                    sx={{
                      marginRight: ".25rem",
                      mb: 1,
                      background: "#FC7335",
                      color: "#FFFFFF",
                    }}
                  />
                ))}
                <br />
                <br />
                <Typography variant="h5">Features</Typography>
                <List>
                  {features.map((feature, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <CheckCircleOutline color="secondary" />
                      </ListItemAvatar>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item size={{ xs: 12, md: 6 }}>
            <ImageCarousel images={images} />
          </Grid>
        </Grid>
      </Container>
      {problem && (
        <Box
          sx={{
            mt: 4,
            mb: 4,
            p: 4,
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <Container>
            <Typography
              variant="h4"
              component="div"
              sx={{ textAlign: "center", mb: 4 }}
            >
              My Work Flow
            </Typography>
            {problem && (
              <Grid container spacing={2} sx={{ pb: 8 }}>
                <Grid size={{ sx: 12, md: 4 }} sx={{ flexGrow: 1 }}>
                  <Card variant="shadow" sx={{ minHeight: "100%" }}>
                    <CardHeader
                      title="Problem"
                      sx={{
                        color: "white",
                        background: (theme) =>
                          theme.palette.background.gradient2,
                        p: 4,
                      }}
                    />
                    <CardContent>
                      <Box sx={{ flexGrow: 1, p: 2 }}>
                        <Typography
                          variant="body1"
                          component="p"
                          sx={{ mb: 1 }}
                        >
                          {problem}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid size={{ sx: 12, md: 4 }} sx={{ flexGrow: 1 }}>
                  <Card variant="shadow" sx={{ minHeight: "100%" }}>
                    <CardHeader
                      title="Solution"
                      sx={{
                        color: "white",
                        background: (theme) => theme.palette.accent.solution,
                        p: 4,
                      }}
                    />
                    <CardContent>
                      <Box sx={{ flexGrow: 1, p: 2 }}>
                        <Typography
                          variant="body1"
                          component="p"
                          sx={{ mb: 1 }}
                        >
                          {solution}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid size={{ sx: 12, md: 4 }} sx={{ flexGrow: 1 }}>
                  <Card variant="shadow" sx={{ minHeight: "100%" }}>
                    <CardHeader
                      title="Outcome"
                      sx={{
                        color: "white",
                        background: (theme) =>
                          theme.palette.background.gradient3,
                        p: 4,
                      }}
                    />
                    <CardContent>
                      <Box sx={{ flexGrow: 1, p: 2 }}>
                        <Typography
                          variant="body1"
                          component="p"
                          sx={{ mb: 1 }}
                        >
                          {outcome}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Container>
          {/* <Container>
          <Grid>
            <br />
            {problem && (
              <Card variant="outlined" sx={{ p: 2 }}>
                <CardContent>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Opportunity
                  </Typography>
                  <Typography variant="body1" component="p" sx={{ mb: 1 }}>
                    {problem}
                  </Typography>

                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Solution
                  </Typography>
                  <Typography variant="body1" component="p" sx={{ mb: 1 }}>
                    {solution}
                  </Typography>

                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Outcome
                  </Typography>
                  <Typography variant="body1" component="p" sx={{ mb: 1 }}>
                    {outcome}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Container> */}
        </Box>
      )}
    </Box>
  );
};

export default ProjectDetail;
