// src/pages/Home.js
import React from "react";
import ProjectDetail from "./ProjectDetail";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import projectList from "../projectList";
import { ArrowBackIos } from "@mui/icons-material";
const Project = () => {
  const { id } = useParams();
  const selectedProject = projectList.filter((e) => e.id === id)[0];
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box sx={{ background: (theme) => theme.palette.background.soft }}>
      {selectedProject ? (
        <Box sx={{ paddingBottom: "4rem", paddingTop: "2rem" }}>
          <Container>
            <Button
              size="large"
              onClick={handleBack}
              variant="back"
              startIcon={<ArrowBackIos />}
            >
              Back
            </Button>

            <Button
              sx={{ ml: 1 }}
              variant="contained"
              color="primary"
              size="large"
              onClickCapture={() => navigate("/projects")}
            >
              View More Projects
            </Button>
          </Container>

          <br />
          <br />
          <ProjectDetail
            title={selectedProject.title}
            link={selectedProject.link}
            company={selectedProject.company}
            description={selectedProject.description}
            problem={selectedProject.problem}
            solution={selectedProject.solution}
            outcome={selectedProject.outcome}
            technologiesUsed={selectedProject.technologiesUsed}
            images={selectedProject.images}
            features={selectedProject.features}
          />
        </Box>
      ) : (
        <Container sx={{ mt: 4 }}>
          <Button
            onClick={handleBack}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIos />}
          >
            Back
          </Button>
          <br />
          <br />
          <Card>
            <CardContent>
              <Alert severity="warning">
                <AlertTitle>Unable to locate the project.</AlertTitle>
              </Alert>
            </CardContent>
          </Card>
        </Container>
      )}
    </Box>
  );
};

export default Project;
