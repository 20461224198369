import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Project from "./pages/Project";
import SkillsPage from "./pages/Skills";
import Footer from "./pages/Footer";
import ContactPage from "./pages/Contact";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra-small devices
      sm: 600, // Small devices
      md: 960, // Medium devices
      lg: 1280, // Large devices
      xl: 1920, // Extra-large devices
    },
  },
  palette: {
    primary: {
      main: "#140E24", // Your primary color/
    },
    secondary: {
      // main: "#2e555f", // Your secondary color
      main: "#262043", // Your secondary color
    },
    accent: {
      main: "#FF1675", // Your accent color (you can customize this as needed)
      solution: "rgba(75, 51, 209, 0.80)",
      success: "#04E287",
    },
    // Optional: Define additional colors if needed
    background: {
      white: "#FFFFFF",
      dark: "#140E24",
      soft: "#2A2753",
      paper: "#F4F7FA", // Paper background color
      meshGradient:
        "background-color:hsla(256,44%,9%,1); background-image: radial-gradient(at 6% 46%, hsla(249,63%,50%,0.8) 0px, transparent 50%), radial-gradient(at 100% 0%, hsla(335,100%,54%,1) 0px, transparent 50%);",
      gradient:
        "linear-gradient(35deg,  #262043 45%,rgba(75, 51, 209, 0.80), #FF1675 98%)",
      gradient2: "linear-gradient(90deg,#262043,rgba(75, 51, 209, 0.80))",
      gradient3: "linear-gradient(90deg,rgba(75, 51, 209, 0.80), #FF1675)",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif", // Customize the font
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontSize: "3rem",
    },
    h4: {
      fontSize: "3rem",
    },
    h5: {
      display: "inline-block",
    },
    h6: {
      fontSize: "1.25rem",
    },
    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "20px",
    },
    // Add other typography settings if needed
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            fontWeight: "bold",
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "999px", // Adds rounded corners
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            fontWeight: "bold",
          },
        },
        {
          props: { variant: "back" },
          style: {
            border: "1px solid white",
            color: "#FFFFFF", // Ensures direct text is white
            "& *": {
              color: "#FFFFFF", // Ensures all nested elements have white text
            },
            fontWeight: "bold",
          },
        },
        {
          props: { variant: "block" },
          style: {
            border: "1px solid white",
            backgroundColor: "rgb(19, 15, 37, 0.5)",
            // backdropFilter: "blur(32px) saturate(180%)",
            color: "#FFFFFF", // Ensures direct text is white
            "& *": {
              color: "#FFFFFF", // Ensures all nested elements have white text
            },
            fontWeight: "bold",
            borderRadius: "16px",
            padding: "2rem",
            width: "100%",
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px", // Adds rounded corners
        },
      },
      variants: [
        {
          props: { variant: "glass" },
          style: {
            boxShadow: "0px 0px 3px rgba(255, 255, 255, .5)",
            backgroundColor: "rgb(19, 15, 37, 0.5)",
            backdropFilter: "blur(32px) saturate(180%)",
            color: "#FFFFFF", // Ensures direct text is white
            "& *": {
              color: "#FFFFFF", // Ensures all nested elements have white text
            },
            // Use theme breakpoints correctly by defining the responsive styles within the `style` object
          },
        },
        {
          props: { variant: "glass-light" },
          style: {
            boxShadow: "0px 0px 3px rgba(255, 255, 255, .5)",
            // backgroundColor: "rgb(19, 15, 37, 0.5)",
            backgroundColor: "rgba(255, 255, 255, .5)",
            backdropFilter: "blur(32px) saturate(180%)",
            // Use theme breakpoints correctly by defining the responsive styles within the `style` object
          },
        },
        {
          props: { variant: "dark" },
          style: {
            border: "1px solid #e0e0e0",
            backgroundColor: "#262043",
            color: "#FFFFFF", // Ensures direct text is white
            "& *": {
              color: "#FFFFFF", // Ensures all nested elements have white text
            },
          },
        },
        {
          props: { variant: "soft" },
          style: {
            backgroundColor: "#433171",
            color: "#FFFFFF", // Ensures direct text is white
            "& *": {
              color: "#FFFFFF", // Ensures all nested elements have white text
            },
          },
        },
        {
          props: { variant: "clay" },
          style: {
            backgroundColor: "#f4f7fa",
          },
        },
        {
          props: { variant: "shadow" },
          style: {
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.20)",
          },
        },
      ],
    },
  },
});

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ContainerBackground>
          <LocationWatcher />
          <AppBar position="sticky">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={toggleSidebar}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6">Taylor Kyle Dearden</Typography>
            </Toolbar>
          </AppBar>
          <Sidebar open={sidebarOpen} onClose={toggleSidebar} />

          {/* Only the routes below are re-rendered on navigation, not the sidebar or the header */}

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/skills" element={<SkillsPage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:id" element={<Project />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>

          {/* Footer will remain constant */}
          <Footer />
        </ContainerBackground>
      </Router>
    </ThemeProvider>
  );
};

export default App;

const LocationWatcher = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top-left on route change
  }, [location]);

  return null; // This component doesn't render anything
};
const ContainerBackground = ({ children }) => {
  const location = useLocation();
  const isHome = location.pathname === "/";

  return (
    <Box
      sx={{
        background: isHome
          ? (theme) => theme.palette.background.paper
          : (theme) => theme.palette.background.paper,
        minHeight: "100vh",
        transition: "background 0.3s ease-in-out",
      }}
    >
      {children}
    </Box>
  );
};
