// src/pages/Home.js
import React from "react";
import ProjectDetail from "./ProjectDetail";
import HighlightedProjects from "../HighlightedProjects";
import projectList from "../projectList";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";

import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box sx={{ background: (theme) => theme.palette.background.dark }}>
      <Box
        sx={{ background: (theme) => theme.palette.background.meshGradient }}
      >
        <Container>
          <br />
          <Button
            size="large"
            onClick={handleBack}
            variant="back"
            startIcon={<ArrowBackIos />}
          >
            Back
          </Button>
          <br />

          <br />
          <Card variant="glass">
            <CardContent>
              <Typography variant={"h4"}>Notable Projects</Typography>
            </CardContent>
          </Card>

          <br />
          <br />
          <HighlightedProjects projects={projectList} />
          <br />
          <br />
        </Container>
      </Box>
    </Box>
  );
};

export default Projects;
