import React from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ContactMail, Handyman, Home, Work } from "@mui/icons-material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Sidebar = ({ open, onClose }) => {
  const items = [
    { text: "Home", icon: <Home />, path: "/" }, // Add path for routing
    { text: "Skills Overview", icon: <Handyman />, path: "/skills" }, // Add path for routing
    { text: "Notable Projects", icon: <Work />, path: "/projects" }, // Add path for routing
    { text: "Contact", icon: <ContactMail />, path: "/contact" }, // Add path for routing
  ];

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        <Typography variant="h5" sx={{ p: 2 }}>
          Taylor Kyle Dearden
        </Typography>
        <List>
          {items.map((item, index) => (
            <ListItem button key={index} component={Link} to={item.path}>
              {" "}
              {/* Use Link */}
              <ListItemIcon
                sx={{ color: (theme) => theme.palette.secondary.main }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
