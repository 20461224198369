import React from "react";
import { Box, Button, CardActionArea, Grid2 } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const HighlightedProjects = ({ projects }) => {
  const navigate = useNavigate();

  const handleProjectClick = (id) => {
    navigate(`/project/${id}`); // Update the URL as needed
  };

  return (
    <Grid2 container spacing={2}>
      {projects.map((project) => (
        <Grid2 size={{ xs: 12, md: 4 }} key={project.id}>
          <Card variant="shadow">
            <CardActionArea
              sx={{ p: 2 }}
              onClick={() => handleProjectClick(project.id)}
            >
              <CardMedia
                component="img"
                image={project.images ? project.images[0] : ""}
                alt={project.title}
                sx={{ maxWidth: "100%" }}
              />
              <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, sm: 12 }}>
                      <Box>
                        <Typography variant="h6" component="div">
                          {project.title}
                        </Typography>
                        <Typography variant="subtitle1" color="secondary">
                          {project.company}
                        </Typography>
                      </Box>
                    </Grid2>
                    <Grid2
                      size={{ xs: 12, sm: 12 }}
                      container
                      justifyContent={{ sm: "flex-end" }}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                      >
                        View work
                      </Button>
                    </Grid2>
                  </Grid2>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );
};

export default HighlightedProjects;
