import React from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Divider,
  Chip,
  Avatar,
  Container,
  Grid2,
  CardActionArea,
  CardHeader,
} from "@mui/material";
import HighlightedProjects from "../HighlightedProjects";
import projectList from "../projectList";
import { useNavigate } from "react-router-dom";
import Taylored from "./Taylored";
import { LinkedIn, FileOpen } from "@mui/icons-material";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ background: (theme) => theme.palette.background.dark }}>
      <Box
        sx={{
          background: (theme) => theme.palette.background.meshGradient,
          pb: 4,
        }}
      >
        <Container sx={{ pt: "60px", pb: "60px" }}>
          <Grid2 container spacing={4}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Taylored />
              <br />
              <Card elevation={3} variant="glass" sx={{ p: 2 }}>
                <CardContent>
                  <Box display="flex" sx={{ mb: 3 }}>
                    <Avatar
                      src="/images/taylor-portfolio.jpg"
                      alt="Taylor Dearden"
                      sx={{ width: 60, height: 60, mr: 2 }}
                    ></Avatar>
                    <Typography variant="h3" gutterBottom>
                      Taylor Kyle Dearden
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    Software Developer with 6+ years of experience creating
                    scalable, user-centric applications using React, TypeScript,
                    Node.js, .NET, and SQL databases. Proficient in modern web
                    technologies and building reliable solutions.
                  </Typography>
                </CardContent>
              </Card>
              <Grid2 container spacing={4} sx={{ mt: 4 }}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Button
                    variant="block"
                    href="https://www.linkedin.com/in/taylor-kyle-dearden/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedIn sx={{ mr: 1 }} /> Linkedin
                  </Button>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Button
                    variant="block"
                    href="/Taylor_Dearden_Software_Engineer_Resume.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FileOpen sx={{ mr: 1 }} /> Resume
                  </Button>
                </Grid2>
              </Grid2>

              <br />
            </Grid2>
            {/* Skills Overview */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <section>
                <Box>
                  <Card elevation={3} variant="outlined" sx={{ p: 2 }}>
                    <CardContent>
                      <Typography variant="h4" gutterBottom>
                        Skills Overview
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Expertise in front-end frameworks like React, state
                        management, API integration, and performance
                        optimization. Strong collaboration with cross-functional
                        teams to deliver high-quality software.
                      </Typography>

                      <Divider sx={{ my: 2 }} />

                      <Typography variant="body1">
                        <strong>Front-End:</strong>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            mt: 1,
                            mb: 2,
                          }}
                        >
                          {[
                            "React",
                            "JavaScript (ES6+)",
                            "TypeScript",
                            "HTML5",
                            "CSS3",
                            "SASS",
                            "JQuery",
                            "Design Libraries",
                          ].map((tech) => (
                            <Chip
                              key={tech}
                              label={tech}
                              sx={{
                                marginRight: ".25rem",
                                mb: 1,
                                background: "#FF1675",
                                color: "#FFFFFF",
                              }}
                            />
                          ))}
                        </Box>
                      </Typography>
                      <Typography variant="body1">
                        <strong>Back-End:</strong>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            mt: 1,
                            mb: 2,
                          }}
                        >
                          {[
                            "Node.js",
                            "Express",
                            ".NET",
                            "C#",
                            "REST APIs",
                          ].map((tech) => (
                            <Chip
                              key={tech}
                              label={tech}
                              sx={{
                                marginRight: ".25rem",
                                mb: 1,
                                background: "#FC7335",
                                color: "#FFFFFF",
                              }}
                            />
                          ))}
                        </Box>
                      </Typography>

                      <Typography variant="body1">
                        <strong>Databases:</strong>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            mt: 1,
                            mb: 2,
                          }}
                        >
                          {["SQL", "MySQL", "PostgreSQL"].map((tech) => (
                            <Chip
                              key={tech}
                              label={tech}
                              sx={{
                                marginRight: ".25rem",
                                mb: 1,
                                background: "#342A5E",
                                color: "#FFFFFF",
                              }}
                            />
                          ))}
                        </Box>
                      </Typography>

                      <Typography variant="body1">
                        <strong>Beyond crafting code:</strong>
                      </Typography>
                      <br />
                      <Typography variant="body1">
                        I approach development with a Product Design mindset,
                        engaging with clients to deeply understand who their
                        product is for and the problems it aims to solve. By
                        focusing on the user’s needs and challenges early in the
                        process, I ensure the solutions I create are both
                        impactful and meaningful.
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClickCapture={() => navigate("/skills")}
                        sx={{ mt: 2 }}
                      >
                        View More Detailed Skills List
                      </Button>
                    </CardContent>
                  </Card>
                </Box>
              </section>

              <br />
              <br />
            </Grid2>
          </Grid2>
        </Container>
      </Box>

      <br />

      <Box sx={{ background: (theme) => theme.palette.background.paper }}>
        <Container>
          <Card variant="clay" sx={{ p: 2, textAlign: "center" }}>
            <CardContent>
              <Typography sx={{ mb: 8 }} variant="h4" gutterBottom>
                Recent Project Highlights
              </Typography>
              <Typography sx={{ mb: 2 }} variant="body1" paragraph></Typography>
              <HighlightedProjects projects={projectList.slice(0, 6)} />

              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClickCapture={() => navigate("/projects")}
                sx={{ mt: 6 }}
              >
                View More Projects
              </Button>
            </CardContent>
          </Card>
        </Container>
        <br />
      </Box>
    </Box>
  );
};

export default HomePage;
