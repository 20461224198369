import React from "react";
import { Box, Typography, Container } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "#140E24",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="body1">
          Copyright © Taylor Dearden 2024 |{" "}
          <a
            href="mailto:taylordearden@gmail.com"
            style={{ color: "#e1b382", textDecoration: "none" }}
          >
            taylordearden@gmail.com
          </a>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
