import { useMemo } from "react";

const Taylored = () => {
  const animation = useMemo(() => {
    return (
      <div className="home-banner">
        <svg viewBox="0 0 1000 700" width="200px">
          <g id="Layer 1">
            <path
              id="draw1"
              strokeWidth="10"
              d="M287.33+339.759C287.33+339.759+288.075+308.062+263.87+339.069C239.665+370.077+216.391+416.243+216.391+416.243C216.391+416.243+196.469+483.311+243.575+433.699C290.681+384.087+311.721+318.398+311.721+318.398C311.721+318.398+294.964+359.971+288.261+383.628C281.558+407.285+276.717+465.855+352.683+376.508L382.101+322.532C382.101+322.532+295.218+526.032+421.574+375.13C421.574+375.13+452.6+334.476+461.724+302.09C470.847+269.705+401.799+651.845+357.004+636.238C357.004+636.238+314.261+642.143+326.913+571.277C326.913+571.277+336.719+502.788+439.752+435.936C542.785+369.084+561.556+202.555+561.556+202.555C561.556+202.555+560.753+176.492+534.306+245.11C507.858+313.729+462.355+449.053+489.087+442.772C515.819+436.49+589.998+315.043+589.998+315.043C589.998+315.043+520.684+419.661+552.474+424.78C552.474+424.78+610.582+423.012+591.868+335.391C591.868+335.391+588.23+403.519+658.642+316.842C658.642+316.842+633.105+395.7+628.473+413.694C623.841+431.688+653.934+434.74+675.132+416.657C675.132+416.657+754.138+346.634+726.734+332.891C726.734+332.891+693.972+333.206+683.071+398.604C672.171+464.001+790.436+387.15+811.084+362.265C831.731+337.38+797.081+293.172+764.804+367.827C732.527+442.483+791.86+403.252+850.955+347.334C910.05+291.415+972.85+92.1354+933.774+79.4273C894.699+66.7193+826.85+424.298+852.782+432.624"
              fill="none"
              strokeLinecap="round"
              opacity="1"
              strokeLinejoin="round"
            />
            <path
              id="draw3"
              strokeWidth="10"
              d="M45.9049+223.609C45.9049+223.609+159.545+165.987+411.924+118.685"
              fill="none"
              strokeLinecap="round"
              opacity="1"
              strokeLinejoin="round"
            />
            <path
              id="draw1"
              strokeWidth="10"
              d="M220.417+198.386C220.417+198.386+177.999+266.464+91.5286+486.854"
              fill="none"
              strokeLinecap="round"
              opacity="1"
              strokeLinejoin="round"
            />
          </g>
        </svg>
        <div className="type-writer">
          <div className="Single-Letter">S</div>
          <div className="Single-Letter">o</div>
          <div className="Single-Letter">f</div>
          <div className="Single-Letter">t</div>
          <div className="Single-Letter">w</div>
          <div className="Single-Letter">a</div>
          <div className="Single-Letter">r</div>
          <div className="Single-Letter">e</div>
        </div>
      </div>
    );
  }, []);
  return <>{animation}</>;
};
export default Taylored;
