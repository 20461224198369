import React, { useState } from "react";
import { Box, IconButton, Grid2, Card } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, OpenInNew } from "@mui/icons-material";
import "./App.css";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const openInNewTab = () => {
    const imageUrl = images[currentIndex];
    window.open(imageUrl, "_blank");
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.main,
        width: "100%",
        minHeight: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "0px 0px 3px rgba(255, 255, 255, .5)",
        borderRadius: '16px'
      }}
    >
      {/* Image Display */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          borderRadius: "16px 16px 0 0",
          overflow: "hidden",
          p: 2
        }}
        key={currentIndex}
      >
        <img
          src={images[currentIndex]}
          alt={`carousel-image-${currentIndex}`}
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        />
        <IconButton
          aria-label="Open in New Tab"
          onClick={openInNewTab}
          sx={{
            color: "#ffffff",
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 500,
            backgroundColor: (theme) => theme.palette.primary.main,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
        >
          <OpenInNew />
        </IconButton>
      </Box>

      {/* Navigation Buttons */}
      <Box
        sx={{
          background: (theme) => theme.palette.primary.main,
          borderRadius: "0 0 16px 16px",
        }}
      >
        <Grid2 container justifyContent="center" alignItems="center">
          <Grid2 item>
            <IconButton
              disabled={images.length < 2}
              sx={{ color: "#ffffff" }}
              onClick={handlePrev}
            >
              <ArrowBackIos />
            </IconButton>
          </Grid2>
          <Grid2 item>
            <IconButton
              disabled={images.length < 2}
              sx={{ color: "#ffffff" }}
              onClick={handleNext}
            >
              <ArrowForwardIos />
            </IconButton>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default ImageCarousel;
