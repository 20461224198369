const projectList = [
  {
    id: "navigator",
    title: "Navigator Upgrade",
    company: "Kuder (2024)",
    description: "",
    problem:
      "Kuder’s Navigator platform, designed to help students explore career interests and colleges, was outdated and struggling to remain competitive in the market.",
    solution:
      "I collaborated with contractors and stakeholders to migrate existing features to React in TypeScript and develop new features under tight deadlines. This included a refreshed design, occupation details pages, college information pages, enhanced portfolio navigation, and progress tracking.",
    outcome:
      "Navigator now boasts a modern design and improved functionality, making it a competitive platform in the market. The updates provide users with a streamlined experience, better tools for career exploration, and more comprehensive resources.",
    technologiesUsed: [
      "ReactTS",
      "Typescript",
      "Material Design",
      "C#",
      ".NET",
    ],
    images: [
      "/images/highlight-navigator.jpg",
      "/images/highlight-navigator2.jpg",
      "/images/highlight-navigator3.jpg",
      "/images/highlight-navigator4.jpg",
      "/images/highlight-navigator5.jpg",
      "/images/highlight-navigator6.jpg",
      "/images/highlight-navigator7.jpg",
    ],
    features: [
      "Upgraded Dashboard",
      "Occupation Exploration",
      "College Analytic Data",
      "New Navigation and Portfolio Flow",
    ],
  },
  {
    id: "c2b",
    title: "Connect 2 Business",
    company: "Kuder (2024)",
    link: "https://kuder.com/connect-2-business/",
    description: "",
    problem:
      "Educators and administrators struggled to efficiently manage and report work-based learning (WBL) opportunities, often bearing the burden of organizing these programs manually.",
    solution:
      "Working with product leaders I led the Front End development efforts to upgrading Kuder’s Connect 2 Business® (C2B), an online platform that automates the organization of student and employer data, aligns WBL opportunities with student career interests, and simplifies reporting to meet state-level requirements.",
    outcome:
      "The improved platform empowered schools to scale their WBL programs more effectively, alleviating the administrative burden and creating better learning experiences for students.",
    technologiesUsed: [
      "ReactTS",
      "Javascript",
      "Carbon Design",
      "JQuery",
      ".NET",
    ],
    images: [
      "/images/highlight-wbl-home.jpg",
      "/images/c2b-2.png",
      "/images/c2b.png",
      "/images/kuder-c2b-create-business-front.png",
    ],
    features: [
      "Student-to-Opportunity Matching",
      "Reporting Tools",
      "Business Portal",
      "Time Tacking and Requirement Reporting",
    ],
  },
  {
    id: "student-portfolio",
    title: "Student Portfolio",
    company: "Kuder (2022)",
    link: "https://help.kuder.com/en/articles/9882547-educators-how-to-explore-student-detail-insights-within-navigator-for-admins",
    description: "",
    problem:
      "Admins needed a comprehensive and actionable way to track students' progress in career readiness, college applications, and work-based learning.",
    solution:
      "Collaborating with product leaders and other developers I built the Student Details page, organizing key student data into five sections (General, Discover, Explore, Plan, Prepare) to allow easy access and management of student information.",
    outcome:
      "The new page enabled admins to quickly access student progress, manage sensitive data, and support students’ career and education plans effectively.",
    technologiesUsed: ["ReactTS", "Carbon Design", "ChartJS"],
    images: [
      "/images/highlight-student-portfolio.jpg",
      "/images/highlight-student-portfolio-2.jpg",
      "/images/highlight-student-portfolio-3.jpg",
      "/images/holland.jpg",
      "/images/print-assessments.jpg",
    ],
    features: [
      "Assessment Results Insights",
      "Student Activity Summary",
      "Career Exploration Favorites",
      "Graduation Plan Tracking",
    ],
  },
  {
    id: "achieve-works",
    title: "AchieveWorks® Integration",
    company: "Kuder (2024)",
    link: "https://kuder.com/blog/kuder-maximizes-career-exploration-engagement-by-expanding-assessment-suite-with-human-esources/",
    description: "",
    problem:
      "Kuder aimed to enhance career exploration for students by expanding their suite of assessments. To achieve this, they partnered with Human eSources to integrate AchieveWorks®  Assessments into Navigator for Students.",
    solution:
      "I collaborated with backend developers to design an effective API structure that facilitated the seamless integration of AchieveWorks®  Assessments. Additionally, I contributed to the front-end development by creating a customized questionnaire interface and designing a user-friendly report to display assessment results.",
    outcome:
      "With the integration of AchieveWorks® Assessments, Kuder has significantly expanded its assessment offerings. This improvement enables Career and Technical Education (CTE) teachers and counselors to provide more personalized guidance, enhancing the overall career exploration experience for students.",
    technologiesUsed: [
      "ReactTS",
      "MUI Design",
      "C#",
      ".NET",
      "Human eSources API",
    ],
    images: [
      "/images/highlight-achieve-works.jpg",
      "/images/highlight-achieve-works-2.jpg",
      "/images/highlight-achieve-works-3.jpg",
    ],
    features: [
      "4 Assessments",
      "Customized Questionaire Interface",
      "Detailed Assessment Results",
      "Progress Dashboard",
    ],
  },
  {
    id: "graduation-plan",
    title: "Graduation Plan",
    company: "Kuder (2023)",
    link: "https://help.kuder.com/en/articles/9620227-students-how-to-use-grad-plan",
    description: "",
    problem:
      "State governments need a way to track student graduation plan progress. Admins need to be able to create custom tasks for students to complete based on grade level.",
    solution:
      "An existing graduation plan system was available but was limited in features. I worked closely with the data team and product leaders to upgrade and migrate the outdated graduation plan system to a new version with more complex features. ",
    outcome:
      "State, District, and School admins can now create graduation plans for their students to complete. Students can now report on their graduation plan activities. The end result is a dynamic report for admins to easily see student progress.",
    technologiesUsed: [
      "ReactTS",
      "Javascript",
      "Carbon Design",
      "JQuery",
      ".NET",
    ],
    images: [
      "/images/highlight-graduation-plan.jpg",
      "/images/highlight-graduation-plan-student.jpg",
      "/images/kuder-graduation-plan-customcard.jpg",
      "/images/student-gp.jpg",
    ],
    features: [
      "Create Custom Graduation Plans",
      "Track Student Progress Through Grade Levels",
      "Generate Graduation Plan Reports",
      "Aggregate and Individual Student Data",
    ],
  },

  {
    id: "azucenas-forest-management",
    title: "Forest Management",
    link: "https://foreststofortune.com/blog/internal-marketplace",
    company: "Las Azucenas (2022)",
    description: "",
    problem:
      "Las Azucenas, a tropical hardwood farm in Guatemala, needed an efficient way to facilitate the buying and selling of young hardwood trees as impact investments, ensuring accurate pricing and streamlined transactions for stakeholders.",
    solution:
      "I created a React application with a Node server secured using Supertokens. The application enables investors to create accounts to view their tree investments, monitor current investment values, and access an online marketplace where they can buy and sell their growing trees.",
    outcome:
      "Las Azucenas now has a robust system to manage the sale of their trees. Tropical hardwood owners are using the system to manage their portfolio.",
    technologiesUsed: [
      "ReactJS",
      "React Boostrap",
      "NodeJS",
      "PostgreSQL",
      "Heroku",
      "Supertokens",
      "Stripe Payment",
    ],
    images: [
      "/images/highlight-az.jpg",
      "/images/highlight-az-dash.jpg",
      "/images/highlight-az-user.jpg",
    ],
    features: [
      "Tropical hardwood tree marketplace",
      "Manage personal portfolio",
      "View value estimates",
      "Custom Document Generator",
    ],
  },
  {
    id: "form-builder",
    title: "Form Builder",
    company: "Kuder (2024)",
    description: "",
    problem:
      "School districts needed an efficient way to mass-send forms for students and parents to complete, with seamless integration into their existing student and parent data systems.",
    solution:
      "Collaborating with the team, I helped develop a feature in Kuder Navigator® that lets admins create form templates with SurveyJS and send secure links to students and parents via email. The system also includes real-time reporting to track form progress and results.",
    outcome:
      "The new form-sending system empowers school districts to streamline their processes, supporting work-based learning programs and other administrative needs. This robust feature enhances Kuder’s platform offering and simplifies complex workflows for educators and administrators.",
    technologiesUsed: [
      "ReactTS",
      "Javascript",
      "Carbon Design",
      "Brevo",
      "SurveyJS",
      ".NET",
    ],
    images: ["/images/highlight-form-builder.jpg", "/images/form-results.jpg"],
    features: [
      "Form Template Builder",
      "Mass Form Send Via Email",
      "Reporting on Form Results",
      "Customized Signature Fields",
    ],
  },
  {
    id: "qcdc",
    title: "QCDC Landing Page",
    company: "Kuder (2024)",
    description: "",

    technologiesUsed: ["Angular", "Typescript", "HTML", "CSS"],
    images: ["/images/highlight-qcdc.jpg", "/images/highlight-qcdc-eng.jpg"],
    features: [
      "Custom Login Page",
      "Rotating Image Carousel",
      "English and Arabic Language Options",
    ],
  },
  {
    id: "esign",
    title: "E-Sign Waiver System",
    link: "https://studioease.net/e-sign-waiver-system",
    company: "Studioease (2021)",
    description: "",
    problem:
      "The Studioease client base is made up of fitness studio owners. Each studio has waivers and agreements they require their customers sign to protect them from liability and assure membership contracts are enforced. Paying for e-signature platforms can be expensive and difficult to integrate with.",
    solution:
      "I worked with the Studioease Team to design and implement our own document signing web application. Through a secure system studio owners can build document templates in a web browser through a text editor. The templates are saved as html which allows for responsive functionality across devices. Signatures are captured with a canvas and securely stored. After a document is signed a pdf version is generated and sent to the customer and studio.",
    outcome:
      "E-Sign now processes thousands of documents every month where studio customers are able to easily sign from their phone, tablet, or laptop. Documents are sent via text links and emails simplifying the onboarding of new customers for our clients.",
    technologiesUsed: [
      "React JS",
      "QuillJS",
      "Node.js",
      "Canvas Signature",
      "PDF Crowd API",
    ],
    images: ["/images/highlight-esign.jpg", "/images/esign-portfolio.png"],
    features: [
      "Customized Document Builder",
      "E-Signature and Initial",
      "Integrated API System",
      "Desktop and Mobile Friendly",
    ],
  },
  {
    id: "studio-player",
    title: "Studio Player System",
    link: "https://studioplayer.wpengine.com/",
    company: "Studioease (2021)",
    description: "",
    problem:
      "Studioplayer is a studio screen management system that allows businesses to control digital screens remotely through an online web application. Studios can select media to be played at scheduled timeframes and remotely update them when new media is needed.",
    solution:
      "My first project when hired by Studioease was to redesign much of the Studioplayer web application which was built in NextJS. I followed general UX design principles to make the application more intuitive and easy to manage. During my time at Studioease I added several updates to the system with new features and capabilities.",
    outcome:
      "Studioplayer is expanding around the country into businesses where screens with promotional media are regularly implemented like fitness studios and casinos.",
    technologiesUsed: ["NextJS", "Node.js", "Customized CSS"],
    images: [
      "/images/highlight-studioplayer.jpg",
      "/images/studioplayer-portfolio.png",
      "/images/sp-2.jpg",
    ],
    features: [
      "Media Content Management",
      "Media Player Timeline",
      "Content Scheduler",
      "User Permission Management",
    ],
  },
  {
    id: "web-booking-plugin",
    title: "Web Booking Plugin",
    link: "https://studioease.net/web-booking-plug-in",
    company: "Studioease (2021)",
    description: "",
    problem:
      "The Studioease client base which is made up of fitness studio owners have websites that need easy online class booking. Our customers use Mindbody’s platform to run their studios but there is a need for a cleaner and easier way to book classes that integrates with their websites.",
    solution:
      "I designed and developed a vanilla javascript widget that can integrate with Studioease customer websites. Developers just have to add a script and html tags where they want the various widget components to appear. CSS and styling can be manipulated by developers to match their company's styling. The widget covers everything from account creation, class booking, online payments, and account management.",
    outcome:
      "Studioease customers now have available a way to easily integrate their class schedules and online payments into their existing websites. No more oddly placed widgets or redirecting to external websites to handle those actions.",
    technologiesUsed: ["Vanilla Javascript", "CSS", "Payment APIs"],
    images: ["/images/highlight-webtools.jpg", "/images/webtools2.png"],
    features: [
      "Easy JS plugin for any website",
      "Book and studio classes",
      "Take payments and setup subscriptions",
      "Account Management",
    ],
  },
  {
    id: "studioease-marketing",
    title: "Studioease",
    link: "https://studioease.net",
    company: "Studioease (2021)",
    description:
      "I designed and developed the Studioease Marketing site (studioease.net). Built with NextJS it serves as an informational and lead capturing site for Studioease Products. It is important to note, that in the years since I've left the company, several of the pages have changed and no longer represent my work.",

    technologiesUsed: ["NextJS", "Javascript", "SCSS"],
    images: ["/images/highlight-studioease.jpg"],
    features: ["Product Features", "Lead Capturing", "Landing Pages"],
  },
  {
    id: "pluralsight-trophy-case",
    title: "Trophy Case",
    link: "https://help.pluralsight.com/hc/en-us/articles/24271504692372-Skills-release-notes-January-2021",
    company: "Pluralsight (2020)",
    description:
      "While an apprentice at Pluralsight I was on a team working on gamifying the leraning process. One of the key pages I worked on was the Trophy Case which highlights users accomplishments with awarded badges.",

    technologiesUsed: ["ReactTS", "NodeJS", "Pluralsight Design"],
    images: ["/images/highlight-trophycase.jpg"],
    features: ["Achievement Badges", "Skills Progress", "Skills Goals"],
  },
  {
    id: "technoglyph",
    title: "Technoglyph ",

    company: " Personal Project (2019)",
    description: "",
    problem:
      "Throughout the western United States are mysterious petroglyphs that to date no one has conclusively deciphered. Many theories about the petroglyphs have emerged but no one has proven an author or message. I believe technology might be the key to solving these questions.",
    solution:
      "Creating a web application that can be used to log and document all of these petroglyphs. By recording petroglyph site locations, individual symbols, symbol positioning on the panels, and symbol details we will be able to see a bigger picture in the data.",
    outcome:
      "Technoglyph was designed and developed as my final project at Dev Mountain in 2019. Over 150+ symbols are logged in the database with over 500 instances of each symbol. The database has been useful for researchers trying to unlock this mystery.",
    technologiesUsed: [
      "React",
      "NodeJS",
      "Bycrypt",
      "GoogleMapsAPI",
      "PostgreSQL",
    ],
    images: [
      "/images/highlight-technoglyph.jpg",
      "/images/technoglyph-map.png",
      "/images/technoglyph-phone.png",
    ],
    features: [
      "Petroglyph Data Entry",
      "Geographical Analysis",
      "Panel-Symbol Relationships",
      "Notes, Interpretations, and Collaboration",
    ],
  },
  {
    id: "artisan-marketplace",
    title: "Artisan Marketplace",
    link: "https://goodalways.org",
    company: "Good Always (2018)",
    description:
      "Good Always is an social enterprise that aims to reduce poverty in Guatemala. One of the ways this is accomplished is by selling products made by artisans. 100% of the profits are used to combat extreme poverty through education, job creation, and providing for basic human needs.",

    technologiesUsed: ["Shopify", "HTML", "CSS"],
    images: ["/images/highlight-goodalways.jpg"],
    features: ["Customized Shopify Store", "Artisan Products", "Social Impact"],
  },
];
export default projectList;
